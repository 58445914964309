import React from "react";
import styles from "./article3.module.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const article3 = () => (
  <div className={styles.article1}>
    <Card>
      <CardContent>
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-8" style={{ textAlign: "left" }}>
              <p className={styles.details}>
                <strong>ΠΟΛΙΤΙΣΤΙΚΟΣ ΕΞΩΡΑΪΣΤΙΚΟΣ ΣΥΛΛΟΓΟΣ</strong>
              </p>
              <p className={styles.details}>
                <strong>«Ο ΑΓΙΟΣ ΧΑΡΑΛΑΜΠΟΣ»</strong>
              </p>
              <p className={styles.details}>
                <strong>ΚΑΛΑΜΑΚΙ- ΚΟΡΙΝΘΙΑΣ</strong>
              </p>
              <p className={styles.details}>ΤΗΛ. 2741 049258</p>
              <p className={styles.details}>Τ.Θ. 41</p>
              <p className={styles.details}>Τ.Κ. 20003 ΙΣΘΜΙΑ</p>
            </div>
            <div class="col-md-3 col-4">19/08/2020</div>
          </div>
          <div class="row">
            <div class="col">
              <h2>ΕΝΗΜΕΡΩΣΗ</h2>
              <p className={styles.content}>
                Η προσπάθεια μας για τον εκσυγχρονισμό του συλλόγου μας και την
                εξεύρεση τρόπων επικοινωνίας με όλους, συνεχίζεται με αμείωτους
                ρυθμούς. <br />
                Έτσι, είμαστε στην ευχάριστη θέση να σας γνωρίσουμε ότι μπορείτε
                να βρείτε τη νέα μας ιστοσελίδα στο ίντερνετ, στη διεύθυνση{" "}
                <a href="https://agcharalampos.gr">www.agcharalampos.gr</a>, και
                να περιηγηθείτε σε νέα, ανακοινώσεις, θέματα της περιοχής μας,
                χρήσιμα τηλέφωνα και ιστότοπους, φωτογραφίες, να βρείτε την
                φόρμα επικοινωνίας και την αίτηση νέου μέλους, καθώς και το
                facebook του συλλόγου μας.{" "}
              </p>
              <p>
                <i>
                  Σε περίπτωση που εσείς αδυνατείτε , ζητήσετε την βοήθεια των
                  μελών της οικογενείας σας, ή του Δ.Σ. του συλλόγου,
                  προκειμένου να συμπληρώσετε την φόρμα επικαιροποίηση των
                  στοιχείων σας
                </i>
              </p>
              <p className={styles.content}>
                Σκοπός του συλλόγου μας είναι η ανάδειξη των προβλημάτων της
                περιοχής μας για την βελτίωση της ποιότητας ζωής των κατοίκων
                της. <br />
                Το Δ.Σ., με την υποστήριξη σας, θα καταβάλει κάθε προσπάθεια
                προς όλους τους αρμόδιους διοικητικούς και κοινωνικούς φορείς,
                όπως δήμο, περιφέρεια, υπηρεσίες κ.α., ασκώντας πίεση και
                κάνοντας όλες τις απαραίτητες ενέργειες μέχρι την επίλυση του
                προβλήματος.
              </p>
              <p className={styles.justCenter}>
                Για μια σωστή αρχή, φρόνιμο είναι να γίνει πλήρη καταγραφή των
                προβλημάτων της περιοχής μας.
              </p>
              <p>
                {" "}
                Έτσι, σας καλούμε όλους, μέλη ή μη, να συμμετέχετε στην έρευνα
                του συλλόγου μας, με τίτλο{" "}
                <strong>
                  <i>«Η άποψή σας μας ενδιαφέρει»</i>
                </strong>{" "}
                και η ανάδειξη{" "}
                <strong>
                  <i>«Εκπροσώπου της γειτονιάς σας»</i>
                </strong>
                .
              </p>
              <p>
                Η διαδικασία αυτή θα θέλαμε να έχει ολοκληρωθεί έως{" "}
                <strong>30/9/20</strong>, έτσι ώστε να ιεραρχήσουμε τα
                προβλήματα και να ασχοληθούμε ενεργά με την επίλυσή τους.
              </p>
              <p>
                Με την συμμετοχή όλων, τα αποτελέσματα της έρευνάς μας να είναι
                το δυνατόν πληρέστερα και πιο ολοκληρωμένα. Έτσι, μετά την
                επεξεργασία τους και μελέτη τους να αναδείξουν τα πραγματικά
                προβλήματα της περιοχής μας.
              </p>
              <p>
                <i>
                  Η ολοκληρωμένη αυτή έρευνα-μελέτη θα δώσει την ευκαιρία στο
                  Δ.Σ. του συλλόγου μας να μπορεί να παρουσιάζει στους αρμόδιους
                  φορείς το μέγεθος και την ένταση των προβλημάτων της περιοχής
                  μας και να διεκδικεί την επίλυσή τους καθώς και με την
                  υπόδειξη του κατάλληλου προσώπου, γείτονά σας, ως Εκπροσώπου
                  της γειτονιάς σας, να εργαστούμε όλοι μαζί για την επίτευξη
                  του κοινού μας στόχου.
                </i>
              </p>
              <p>
                Το Ερωτηματολόγιο για τη συμμετοχή σας στην έρευνά μας, μπορείτε
                να το προμηθευτείτε και να το παραδώσετε συμπληρωμένο :
              </p>
              <p>
                <ul style={{ textAlign: "left" }}>
                  <li>Σε όλα τα μέλη του Δ.Σ.</li>
                  <li>
                    Στην ιστοσελίδα του συλλόγου μας{" "}
                    <a href="https://agcharalampos.gr">www.agcharalampos.gr</a>
                  </li>
                  <li>
                    Στο facebook ως Εξωραϊστικός Πολιτιστικός Σύλλογος «’Αγιος
                    Χαράλαμπος»
                  </li>
                  <li>
                    Στα γραφεία του συλλόγου μας κάθε Σάββατο και Κυριακή έως
                    30/9/20 από 10:00-11:00πμ
                  </li>
                  <li>Στο minimarket ΚΥΡΙΑΚΗΣ , οδός Αγ. Χαραλάμπους</li>
                </ul>
              </p>
              <p>
                <strong>
                  Σ’ αυτή μας την προσπάθεια δεν περισσεύει κανείς! Είμαστε όλοι
                  μαζί , είμαστε Δυνατοί !
                </strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>
                <strong>Ο ΠΡΟΕΔΡΟΣ</strong>
              </p>
              <p>Κωνσταντίνος Λαιμός</p>
              <p>Τηλ. 697 4466272</p>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <p>
                <strong>Η ΓΡΑΜΜΑΤΕΑΣ</strong>
              </p>
              <p>Ιωάννα Κασσιμάτη</p>
              <p>Τηλ.693 222761</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://firebasestorage.googleapis.com/v0/b/agcharalampos-ef2e0.appspot.com/o/%CE%95%CE%9D%CE%97%CE%9C%CE%95%CE%A1%CE%A9%CE%A3%CE%97%20%CE%97%20%CE%91%CE%A0%CE%9F%CE%A8%CE%97%20%CE%A3%CE%91%CE%A3%20%CE%9C%CE%91%CE%A3%20%CE%95%CE%9D%CE%94%CE%99%CE%91%CE%A6%CE%95%CE%A1%CE%95%CE%99.pdf?alt=media&token=ef68c5df-1707-444f-a02e-6a8ef5dbe09e"
                >
                  Για το Ερωτηματολόγιο πατήστε εδώ...
                </a>
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </div>
);

article3.propTypes = {};

article3.defaultProps = {};

export default article3;
