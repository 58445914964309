import img1 from "../../images/1.jpg";
import img2 from "../../images/2.jpg";
import img3 from "../../images/3.jpg";
import img4 from "../../images/4.jpg";
import img5 from "../../images/5.jpg";
import img6 from "../../images/6.jpg";
import img7 from "../../images/7.jpg";
import img8 from "../../images/8.jpg";
import img9 from "../../images/9.jpg";
import img10 from "../../images/10.jpg";
import img11 from "../../images/11.jpg";
import img12 from "../../images/12.jpg";
import img13 from "../../images/13.jpg";
import img14 from "../../images/14.jpg";
import img15 from "../../images/15.jpg";
import img16 from "../../images/16.jpg";
import img17 from "../../images/17.jpg";
import img18 from "../../images/18.jpg";
import img19 from "../../images/19.jpg";
import img20 from "../../images/20.jpg";
import img21 from "../../images/21.jpg";
import img22 from "../../images/22.PNG";
import img30 from "../../images/30.jpg";
import img31 from "../../images/31.jpg";
import img32 from "../../images/32.jpg";
import img33 from "../../images/33.jpg";
import img34 from "../../images/34.jpg";
import img35 from "../../images/35.jpg";
import img36 from "../../images/36.jpg";
import apokries from "../../images/apokries.jpg";
import img23 from "../../images/paraliailiakti.jpg";
import Euxes from "../../images/euxes.jpg";
import img37 from "../../images/ΦΩΤΟ χριστούγεννα 1.jpg";
import img38 from "../../images/ΦΩΤΟ χριστούγεννα 2.jpg";
import img39 from "../../images/ΦΩΤΟ χριστούγεννα 3.jpg";
import img40 from "../../images/ΦΩΤΟ χριστούγεννα 4.jpg";
import img41 from "../../images/ΦΩΤΟ χριστούγεννα 5.jpg";
import img43 from "../../images/ΦΩΤΟ 6 ΚΑΘΑΡΑ ΔΕΥΤΕΡΑ 2021.jpg";
import img42 from "../../images/ΦΩΤΟ 7 25η ΜΑΡΤΙΟΥ 2021.jpg";
import img44 from "../../images/ΦΩΤΟ 8.jpg";
import img45 from "../../images/ΦΩΤΟ 9.jpeg";
import img46 from "../../images/ΦΩΤΟ 10.jpeg";
import img47 from "../../images/ΦΩΤΟ 11.jpeg";
import img48 from "../../images/ΦΩΤΟ 12.jpeg";
import img49 from "../../images/ΦΩΤΟ ΠΑΣΧΑ 1.jpg";
import img50 from "../../images/ΦΩΤΟ ΠΑΣΧΑ 2.jpg";
import img51 from "../../images/ΦΩΤΟ ΠΑΣΧΑ 3.jpg";
import img52 from "../../images/ΦΩΤΟ ΠΑΣΧΑ 4.jpg";
import img53 from "../../images/ΦΩΤΟ ΠΑΣΧΑ 5.jpg";
import img54 from "../../images/ΦΩΤΟ ΠΑΣΧΑ 6.jpg";
import Apokries from "../../images/pasxa.jpg";
import kalokairi from "../../images/kalokairi.jpg";
import kalokairi1 from "../../images/kalokairi1.jpg";
import kalokairi2 from "../../images/kalokairi2.jpg";
import xrist1 from "../../images/xrist1.jpeg";
import xrist2 from "../../images/xrist2.jpg";
import xrist3 from "../../images/xrist3.jpg";
import xrist4 from "../../images/xrist4.jpg";
import xrist5 from "../../images/xrist5.jpg";
import xrist6 from "../../images/xrist6.jpg";
import xrist7 from "../../images/xrist7.jpg";
import xrist8 from "../../images/xrist8.jpg";
import xrist9 from "../../images/xrist9.jpg";
import xrist10 from "../../images/xrist10.jpg";
import xrist11 from "../../images/xrist11.jpg";
import xrist12 from "../../images/xrist12.jpg";
import xrist13 from "../../images/xrist13.jpg";
import Wifi from "../../images/wifi.jpg";

export const photos = [
  {
    src: Wifi,
    width: 4,
    height: 3,
  },
  {
    src: xrist13,
    width: 4,
    height: 3,
  },
  {
    src: xrist12,
    width: 4,
    height: 3,
  },
  {
    src: xrist11,
    width: 4,
    height: 3,
  },
  {
    src: xrist10,
    width: 4,
    height: 3,
  },
  {
    src: xrist9,
    width: 3,
    height: 4,
  },
  {
    src: xrist8,
    width: 4,
    height: 3,
  },
  {
    src: xrist7,
    width: 3,
    height: 4,
  },
  {
    src: xrist6,
    width: 3,
    height: 4,
  },
  {
    src: xrist5,
    width: 4,
    height: 3,
  },
  {
    src: xrist4,
    width: 3,
    height: 4,
  },
  {
    src: xrist3,
    width: 3,
    height: 4,
  },
  {
    src: xrist2,
    width: 4,
    height: 3,
  },
  {
    src: xrist1,
    width: 3,
    height: 4,
  },
  {
    src: kalokairi2,
    width: 3,
    height: 4,
  },
  {
    src: kalokairi1,
    width: 3,
    height: 4,
  },
  {
    src: kalokairi,
    width: 3,
    height: 4,
  },

  {
    src: img23,
    width: 3,
    height: 4,
  },
  {
    src: img22,
    width: 3,
    height: 4,
  },
  {
    src: img1,
    width: 3,
    height: 4,
  },
  {
    src: img2,
    width: 3,
    height: 4,
  },
  {
    src: img3,
    width: 3,
    height: 4,
  },
  {
    src: img4,
    width: 3,
    height: 4,
  },
  {
    src: img5,
    width: 3,
    height: 4,
  },
  {
    src: img6,
    width: 3,
    height: 4,
  },
  {
    src: img7,
    width: 3,
    height: 4,
  },
  {
    src: img8,
    width: 3,
    height: 4,
  },
  {
    src: img9,
    width: 3,
    height: 4,
  },
  {
    src: img10,
    width: 3,
    height: 4,
  },
  {
    src: img11,
    width: 3,
    height: 4,
  },
  {
    src: img12,
    width: 3,
    height: 4,
  },
  {
    src: img13,
    width: 3,
    height: 4,
  },
  {
    src: img14,
    width: 3,
    height: 4,
  },
  {
    src: img15,
    width: 3,
    height: 4,
  },
  {
    src: img16,
    width: 3,
    height: 4,
  },
  {
    src: img17,
    width: 3,
    height: 4,
  },
  {
    src: img18,
    width: 3,
    height: 4,
  },
  {
    src: img19,
    width: 3,
    height: 4,
  },
  {
    src: img20,
    width: 3,
    height: 4,
  },
  {
    src: img21,
    width: 3,
    height: 4,
  },
  {
    src: Euxes,
    width: 3,
    height: 4,
  },
  {
    src: img37,
    width: 3,
    height: 4,
  },
  {
    src: img38,
    width: 3,
    height: 4,
  },
  {
    src: img39,
    width: 3,
    height: 4,
  },
  {
    src: img40,
    width: 3,
    height: 4,
  },
  {
    src: img41,
    width: 3,
    height: 4,
  },
  {
    src: img30,
    width: 3,
    height: 4,
  },
  {
    src: img31,
    width: 3,
    height: 4,
  },
  {
    src: img32,
    width: 3,
    height: 4,
  },
  {
    src: img33,
    width: 3,
    height: 4,
  },
  {
    src: img34,
    width: 3,
    height: 4,
  },
  {
    src: img35,
    width: 3,
    height: 4,
  },
  {
    src: img36,
    width: 3,
    height: 4,
  },
  {
    src: apokries,
    width: 3,
    height: 4,
  },
  {
    src: img42,
    width: 4,
    height: 3,
  },
  {
    src: img43,
    width: 4,
    height: 3,
  },
  {
    src: img44,
    width: 4,
    height: 3,
  },
  {
    src: img45,
    width: 4,
    height: 3,
  },
  {
    src: img46,
    width: 4,
    height: 3,
  },
  {
    src: img47,
    width: 4,
    height: 3,
  },
  {
    src: img48,
    width: 4,
    height: 3,
  },
  {
    src: Apokries,
    width: 4,
    height: 3,
  },
  {
    src: img49,
    width: 4,
    height: 3,
  },
  {
    src: img50,
    width: 3,
    height: 4,
  },
  {
    src: img51,
    width: 3,
    height: 4,
  },
  {
    src: img52,
    width: 3,
    height: 4,
  },
  {
    src: img53,
    width: 3,
    height: 4,
  },
  {
    src: img54,
    width: 3,
    height: 4,
  },
];
