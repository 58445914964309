import img1 from '../../images/metropolitan2.jpg';
import img2 from '../../images/metropolitan.jpg';
import img22 from '../../images/nycprosfora.PNG';
import img4 from '../../images/joanaprosfora.PNG';
import img5 from '../../images/prosforapapa.PNG';
import img6 from '../../images/prosfores2.PNG';

export const photos = [
    
    {
        src: img2,
        width: 4,
        height:3
    },
    {
        src: img1,
        width: 4,
        height:3
    },
    {
        src: img6,
        width: 3,
        height:4
    },
    {
        src: img22,
        width: 3,
        height:4
    },
    {
        src: img4,
        width: 3,
        height:4
    },
    {
        src: img5,
        width: 3,
        height:4
    },
]