import React from "react";
import styles from "./article8.module.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const article7 = () => (
  <div className={styles.article6}>
    <Card>
      <CardContent>
        <div class="container">
          <div style={{ paddingTop: "2em" }}></div>
          <div class="row">
            <div class="col">
              <h2 style={{ color: "red" }}>korinthosTV</h2>
              <p className={styles.content}>
                ΑΡΘΡΟ ΓΙΑ ΤΗ ΣΥΝΕΧΙΖΟΜΕΝΗ ΡΥΠΑΝΣΗ ΤΗΣ ΠΑΡΑΛΙΑΣ ΑΓΑΠΗΣ ΑΠΟ ΤΟ
                zougla.gr
                <br />
                <a href="https://www.zougla.gr/greece/article/katagelia-sinexizete-aproskopta-i-molinsi-tis-paralias-agapis-sto-loutraki-me-toksika-ilika">
                  https://www.zougla.gr/greece/article/katagelia-sinexizete-aproskopta-i-molinsi-tis-paralias-agapis-sto-loutraki-me-toksika-ilika
                </a>
                <br />
                ΑΝΑΦΟΡΑ ΑΠΟ ΤΟ BLOG «ΛΙΜΕΝΙΚΑ ΝΕΑ»
                <br />
                <a href="https://www.limenikanea.gr/gr/limeniko/sunexizetai-aproskopta-i-molunsi-tis-paralias-agapi-sto-loutraki-me-toksika-ulika-19830">
                  https://www.limenikanea.gr/gr/limeniko/sunexizetai-aproskopta-i-molunsi-tis-paralias-agapi-sto-loutraki-me-toksika-ulika-19830
                </a>
                <br />
                ΑΝΑΦΟΡΑ ΑΠΟ ΤΟ BLOG «ΕΥΡΥΤΑΝΙΚΑ ΝΕΑ»
                <br />
                <a href="https://www.evrytanika.gr/index.php?option=com_content&view=article&id=1581065:%CF%83%CF%85%CE%BD%CE%B5%CF%87%CE%AF%CE%B6%CE%B5%CF%84%CE%B1%CE%B9-%CE%B1%CF%80%CF%81%CF%8C%CF%83%CE%BA%CE%BF%CF%80%CF%84%CE%B1-%CE%B7-%CE%BC%CF%8C%CE%BB%CF%85%CE%BD%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CF%80%CE%B1%CF%81%CE%B1%CE%BB%CE%AF%CE%B1%CF%82-%C2%AB%CE%B1%CE%B3%CE%AC%CF%80%CE%B7%CF%82%C2%BB-%CF%83%CF%84%CE%BF-%CE%BB%CE%BF%CF%85%CF%84%CF%81%CE%AC%CE%BA%CE%B9-%CE%BC%CE%B5-%CF%84%CE%BF%CE%BE%CE%B9%CE%BA%CE%AC-%CF%85%CE%BB%CE%B9%CE%BA%CE%AC&catid=53:2011-11-23-14-55-18&Itemid=146">
                  https://www.evrytanika.gr/index.php?option=com_content&view=article&id=1581065:%CF%83%CF%85%CE%BD%CE%B5%CF%87%CE%AF%CE%B6%CE%B5%CF%84%CE%B1%CE%B9-%CE%B1%CF%80%CF%81%CF%8C%CF%83%CE%BA%CE%BF%CF%80%CF%84%CE%B1-%CE%B7-%CE%BC%CF%8C%CE%BB%CF%85%CE%BD%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CF%80%CE%B1%CF%81%CE%B1%CE%BB%CE%AF%CE%B1%CF%82-%C2%AB%CE%B1%CE%B3%CE%AC%CF%80%CE%B7%CF%82%C2%BB-%CF%83%CF%84%CE%BF-%CE%BB%CE%BF%CF%85%CF%84%CF%81%CE%AC%CE%BA%CE%B9-%CE%BC%CE%B5-%CF%84%CE%BF%CE%BE%CE%B9%CE%BA%CE%AC-%CF%85%CE%BB%CE%B9%CE%BA%CE%AC&catid=53:2011-11-23-14-55-18&Itemid=146
                </a>
                 <br />
                ΝΟΕΜΒΡΙΟΣ 2020 ΑΝΑΦΟΡΑ ΑΠΟ ΤΟ BLOG «The Newspaper»
                <br /> 
                <a href="https://www.thenewspaper.gr/2020/11/09/%CF%83%CF%85%CE%BD%CE%B5%CF%87%CE%AF%CE%B6%CE%B5%CF%84%CE%B1%CE%B9-%CE%B1%CF%80%CF%81%CF%8C%CF%83%CE%BA%CE%BF%CF%80%CF%84%CE%B1-%CE%B7-%CE%BC%CF%8C%CE%BB%CF%85%CE%BD%CF%83%CE%B7-%CF%84%CE%B7%CF%82/">
                  https://www.thenewspaper.gr/2020/11/09/%CF%83%CF%85%CE%BD%CE%B5%CF%87%CE%AF%CE%B6%CE%B5%CF%84%CE%B1%CE%B9-%CE%B1%CF%80%CF%81%CF%8C%CF%83%CE%BA%CE%BF%CF%80%CF%84%CE%B1-%CE%B7-%CE%BC%CF%8C%CE%BB%CF%85%CE%BD%CF%83%CE%B7-%CF%84%CE%B7%CF%82/
                </a>
                <br />
                ΑΝΑΦΟΡΑ ΑΠΟ ΤΟ BLOG «Peloponnhsos Nea»
                <br />
                <a href="http://www.palo.gr/peloponnhsos-nea/korinthia-ksefyge-i-katastasi-molynoyn-tin-paralia-me-toksika-ylika-fwto-o-tagaras-pali-den-akoyse-i-den-eide-tipota/26406331/">
                  http://www.palo.gr/peloponnhsos-nea/korinthia-ksefyge-i-katastasi-molynoyn-tin-paralia-me-toksika-ylika-fwto-o-tagaras-pali-den-akoyse-i-den-eide-tipota/26406331/
                </a>
                <br />
                ΑΝΑΦΟΡΑ ΑΠΟ ΤΟ BLOG «Wordpress Volou»
                <br />
                <a href="https://voliotaki.gr/wordpress/2020/11/%cf%83%cf%85%ce%bd%ce%b5%cf%87%ce%af%ce%b6%ce%b5%cf%84%ce%b1%ce%b9-%ce%b1%cf%80%cf%81%cf%8c%cf%83%ce%ba%ce%bf%cf%80%cf%84%ce%b1-%ce%b7-%ce%bc%cf%8c%ce%bb%cf%85%ce%bd%cf%83%ce%b7-%cf%84%ce%b7%cf%82.html">
                  https://voliotaki.gr/wordpress/2020/11/%cf%83%cf%85%ce%bd%ce%b5%cf%87%ce%af%ce%b6%ce%b5%cf%84%ce%b1%ce%b9-%ce%b1%cf%80%cf%81%cf%8c%cf%83%ce%ba%ce%bf%cf%80%cf%84%ce%b1-%ce%b7-%ce%bc%cf%8c%ce%bb%cf%85%ce%bd%cf%83%ce%b7-%cf%84%ce%b7%cf%82.html
                </a>
                <br />
                ΑΝΑΦΟΡΑ ΑΠΟ ΤΟ BLOG «Notospress.gr»
                <br />
                <a href="https://notospress.gr/mobile/article.php?id=55351">
                  https://notospress.gr/mobile/article.php?id=55351
                </a>
              </p>
            </div>
          </div>
          <div class="row" style={{ paddingBottom: "2em" }}>
            <div class="col"></div>
          </div>
        </div>
      </CardContent>
    </Card>
  </div>
);

article7.propTypes = {};

article7.defaultProps = {};

export default article7;
