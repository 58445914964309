import img1 from '../../images/1.png';
import img2 from '../../images/2.png';
import img3 from '../../images/3.png';
import img4 from '../../images/4.png';
import img5 from '../../images/5.png';
import img6 from '../../images/6.png';
import img7 from '../../images/7.png';
import img8 from '../../images/8.png';
import img9 from '../../images/9.png';
import img10 from '../../images/10.png';
import img11 from '../../images/11.png';
import img12 from '../../images/12.png';
import img13 from '../../images/13.png';
import img14 from '../../images/14.png';
import img15 from '../../images/15.png';
import img16 from '../../images/16.png';
import img17 from '../../images/17.png';
import img18 from '../../images/18.png';
import img19 from '../../images/19.png';
import img20 from '../../images/20.png';
import img21 from '../../images/21.png';
import img22 from '../../images/22f.png';
import img29 from '../../images/29.jpg';
export const photos = [
    {
        src: img29,
        width: 3,
        height:4
    },
    {
        src: img22,
        width: 3,
        height:4
    },
    {
        src: img1,
        width: 3,
        height:4
    },
    {
        src: img2,
        width: 3,
        height:4
    },
    {
        src: img3,
        width: 3,
        height:4
    },
    {
        src: img4,
        width: 3,
        height:4
    },
    {
        src: img5,
        width: 3,
        height:4
    },
    {
        src: img6,
        width: 3,
        height:4
    },
    {
        src: img7,
        width: 3,
        height:4
    },
    {
        src: img8,
        width: 3,
        height:4
    },
    {
        src: img9,
        width: 3,
        height:4
    },
    {
        src: img10,
        width: 3,
        height:4
    },
    {
        src: img11,
        width: 3,
        height:4
    },
    {
        src: img12,
        width: 3,
        height:4
    },
    {
        src: img13,
        width: 3,
        height:4
    },
    {
        src: img14,
        width: 3,
        height:4
    },
    {
        src: img15,
        width: 3,
        height:4
    },
    {
        src: img16,
        width: 3,
        height:4
    },
    {
        src: img17,
        width: 3,
        height:4
    },
    {
        src: img18,
        width: 3,
        height:4
    },
    {
        src: img19,
        width: 3,
        height:4
    },
    {
        src: img20,
        width: 3,
        height:4
    },
    {
        src: img21,
        width: 3,
        height:4
    }
]