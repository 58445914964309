import React from "react";
import styles from "./tilefona.module.css";
import Divider from "@material-ui/core/Divider";

const tilefona = () => (
  <div className={styles.tilefona}>
    <h2 style={{ paddingBottom: "1em" }}>ΧΡΗΣΙΜΑ ΤΗΛΕΦΩΝΑ</h2>
    <div class="container">
      <div class="row">
        <div class=" "></div>
        <div class="col-md-6 col-7">
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Κέντρο Άμεσης Δράσης
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Δημοτική Αστυνομία Λουτρακίου- Αγ. Θεοδώρων- Περαχώρας
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Αστυνομικό Τμήμα Αγ. Θεοδώρων{" "}
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            ΕΚΑΒ
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Γενικό Νοσοκομείο Κορίνθου
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Κέντρο Υγείας Λουτρακίου
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Ευρωπαϊκός Αριθμός Έκτακτης Ανάγκης
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Πυροσβεστική
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Δασαρχείο
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Λιμεναρχείο Ισθμίας
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Λιμεναρχείο Κορίνθου
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            ΚΕΠ Αγ. Θεοδώρων
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            ΚΕΠ Λουτρακίου
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            ΚΤΕΛ Νομού Κορινθίας
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Δημοτική Κοινότητα Ισθμίων
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Δήμος Λουτρακίου – Αγ. Θεοδώρων{" "}
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Δήμος Κορίνθου επικοινωνία πολιτών{" "}
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Ράδιο Ταξί Λουτρακίου – Ισθμού{" "}
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            Σύλλογος Ιδιοκτητών Ταξί Κορίνθου
          </p>
        </div>
        <div class="col-md-6 col-5">
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            100
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27443 60220
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27410 67222
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            166
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27413 61400 - 27410 25711
          </p>
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27440 63444
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            112
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            199
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27410 24578 - 27410 28276
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27410 37777
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27410 28888
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27413 62401{" "}
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27443 60200
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27410 83000 - 27410 75410
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27410 37211
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27443 60110{" "}
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27413 62100
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            27440 61000
          </p>{" "}
          <Divider />
          <p style={{ paddingBottom: "1em" }} className={styles.txt}>
            18300
          </p>
        </div>
        <div class=""></div>
      </div>
    </div>
  </div>
);

tilefona.propTypes = {};

tilefona.defaultProps = {};

export default tilefona;
